<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mt-4 pa-0">
        <v-sheet outlined class="py-3 px-2">
          <v-text-field id="search-input" dense solo flat hide-details clearable placeholder="검색어를 입력하세요" v-model="keywordState"
            @keyup.enter="onSearchKeyUpEnter"
            prepend-inner-icon="mdi-magnify" @click:prepend-inner="onSearchButtonClick"
            @click:clear="onClearButtonClick" />
        </v-sheet>
      </v-col>
    </v-row>
    <v-row class="white" v-if="articles && articles.length > 0">
      <v-col cols="12" class="pa-0">
        <template v-for="(item, index) in articles">
          <v-card elevation="0" :key="item.id">
            <v-card-text class="text-caption" v-html="formatPublished(item.published)" />
            <v-img max-height="200" class="mx-4 grey lighten-4" :src="item.thumbnailUrl" v-if="item.thumbnailUrl" @click="goToArticlePage(item.id)">
              <template v-slot:placeholder>
                <v-row align="center" justify="center" class="fill-height ma-0" />
              </template>
            </v-img>
            <v-card-title @click="goToArticlePage(item.id)">
              <div class="article-content" v-html="item.title" />
            </v-card-title>
            <v-card-subtitle>
              <div class="article-content two-line" v-html="item.content" />
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer />
              <v-btn icon>
                <v-icon>mdi-export-variant</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-divider class="mx-4" :key="-index" v-if="index < articles.length - 1" />
        </template>
      </v-col>
    </v-row>
    <v-row class="white" v-else-if="error">
      <v-container class="white message-container">
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12" class="text-center">
            <div class="text-h6 grey--text">조회 오류</div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row class="white" v-else>
      <v-container class="white message-container">
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12" class="text-center">
            <div class="text-h6 grey--text">검색결과 없음</div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center" class="mb-8 pa-0">
        <v-btn icon v-intersect="onIntersect" v-show="hasNext">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'Search',
  computed: {
    ...mapState ({
      loading: state => state.app.loading,
      keyword: state => state.articles.keyword,
      name: state => state.articles.name,
      page: state => state.articles.page,
      query: state => state.articles.query,
      articles: state => state.articles.items,
      hasNext: state => state.articles.hasNext,
      error: state => state.articles.itemsError,
    }),
    data() {
      return {
        searchInput: null,
      };
    },
    keywordState: {
      get() {
        console.log('keywordState.get: keyword=', this.keyword);

        return this.keyword;
      },
      set(value) {
        console.log('keywordState.set: value=', value);

        this.setKeyword(value);
      },
    },
  },
  created() {
    console.log('Search.created: keyword=', this.keyword, ', query=', this.query);

    this.setKeyword(this.query);
  },
  mounted() {
    this.searchInput = document.querySelector('#search-input');

    console.log('mounted: searchInput=', this.searchInput);
  },
  methods: {
    ...mapActions('articles', [
      'searchArticles',
    ]),
    ...mapMutations('articles', [
      'setKeyword',
      'initializeItems',
    ]),
    goToArticlePage(id) {
      this.$router.push({ path: `/articles/${id}`});
    },
    fetchArticles() {
      console.log('fetchArticles: page=', this.page, ', articles=', (this.articles ? this.articles.length : -1));

      this.searchArticles(this.query);
    },
    searchArticlesIfValid() {
      console.log('searchArticlesIfValid: keyword=', this.keyword, ', query=', this.query);

      if (this.keyword) {
        this.initializeItems();
        this.searchArticles(this.keyword);
      }
    },
    formatPublished(date) {
      return date.substring(0, 10);
    },
    onSearchKeyUpEnter(event) {
      console.log('onSearchKeyUpEnter:', event);

      this.searchInput.blur();
      this.searchArticlesIfValid();
    },
    onSearchButtonClick(event) {
      console.log('onSearchButtonClick:', event);

      this.searchInput.blur();
      this.searchArticlesIfValid();
    },
    onClearButtonClick(event) {
      console.log('onClearButtonClick:', event);

      this.initializeItems();
    },
    onIntersect(entries, observer, isIntersecting) {
      console.log('onIntersect: isIntersecting=', isIntersecting, ', articles=', (this.articles ? this.articles.length : -1), ', loading=', this.loading);

      if (!isIntersecting || this.loading) {
        return;
      }

      setTimeout(() => {
        if (this.loading) {
          console.log('onIntersect: BLOCK');
          return;
        }

        this.fetchArticles();
      }, 500);
    }
  },
};
</script>

<style scoped>
.container {
  background-color: #f6f6f6;
}
.message-container {
  height: 200px;
}
.two-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.list-item-title-three-line {
  white-space: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.article-skeleton >>> .v-skeleton-loader__heading {
  margin: 16px 0px !important;
}
.article-skeleton >>> .v-skeleton-loader__paragraph {
  padding: 16px 0px;
}
.article-content >>> .query {
  color: #2196f3;
}
</style>
